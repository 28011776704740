import React from 'react';
import { Link } from 'react-router-dom';

export default () => (
  <footer id='footer'>
    <div className='inner'>
      <ul className='icons'>
        <li>
          <a href='https://www.facebook.com/proisolucoes' target="_blank" rel="noopener noreferrer" aria-label='facebook'>
            <i aria-hidden='true' className='icon brands fa-facebook-f' />
          </a>
        </li>
        <li>
          <a href='https://www.instagram.com/proisolucoes/' target="_blank" rel="noopener noreferrer" aria-label='instagram'>
            <i aria-hidden='true' className='icon brands fa-instagram' />
          </a>
        </li>
        <li>
          <a href='https://www.youtube.com/channel/UCOGIZpzABxyv-_Wo3vv-13w' target="_blank" rel="noopener noreferrer" aria-label='youtube'>
            <i aria-hidden='true' className='icon brands fa-youtube' />
          </a>
        </li>
        <li>
          <a href='https://www.linkedin.com/company/proi-solucoes' target="_blank" rel="noopener noreferrer" aria-label='linkedin'>
            <i aria-hidden='true' className='icon brands fa-linkedin-in' />
          </a>
        </li>
        <li>
          <a href='mailto:fernando.proenca@proencaeproenca.com.br' target="_blank" rel="noopener noreferrer" aria-label='E-mail'>
            <i aria-hidden='true' className='icon solid fa-envelope' />
          </a>
        </li>
      </ul>
      <ul className='contact'>
        <li>Av. Cassiano Ricardo, nº 601 - Sala 61/63</li>
        <li>6º andar - Ed. The One</li>
        <li>Jardim Aquarius. São José dos Campos - SP</li>
      </ul>
      <ul className='links'>
        <li>
          <Link to='/'>Home</Link>
        </li>
        <li>
          <Link to='/servicos'>Serviços</Link>
        </li>
        {/* <li>
          <Link to='/videos'>Vídeos</Link>
        </li> */}
        <li>
          <Link to='/sobre-nos'>Sobre Nós</Link>
        </li>
        <li>
          <Link to='/contato'>Contato</Link>
        </li>
      </ul>
      <p className='copyright'>
        Proi Soluções. Todos os Direitos Reservados. &copy; 2020
      </p>
    </div>
  </footer>
);
