import React from 'react';

export default props => {
  const content = (
    <>
      {props.small ? <h3>{props.title}</h3> : <h2>{props.title}</h2>}
      <p>{props.children}</p>
    </>
  );
  return (
    <section>
      {props.image ? (
        <span className='image'>
          <img src={props.image} alt='Spotlight' />
        </span>
      ) : (
        <span className={`icon solid major fa-${props.icon}`}></span>
      )}
      {props.children ? <div className='content'>{content}</div> : content}
    </section>
  );
};
