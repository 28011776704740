import React from 'react';

import Section from '../components/Section';
import Feature from '../components/Feature';
import { Link } from 'react-router-dom';

export default () => (
  <>
  <Section
    id='main'
    type='2'
    header='Serviços'
    headerContent='Seja a contabilidade ou o jurídico, nós temos o que a sua empresa precisa'
    content="features"
    outer
  >
  <Feature small title='Jurídico' icon='file-alt'>
    Cuide de todos os processos legais, ou receba assessoria para a tomada de decisões
    jurídicas
  </Feature>
  <Feature small title='Sistemas' icon='cloud'>
    Informatize a sua empresa com o nosso ERP proprietário ou consulte para a criação
    de soluções personalizadas
  </Feature>
  <Feature small title='Medicina do Trabalho' icon='medkit'>
  Seja qual for o tipo de negócio, é essencial entender o que é a medicina do trabalho e como aplicar no dia a dia
  </Feature>
  <Feature small title='Contábil' icon='calculator'>
  O controle financeiro e contábil na medida certa para a sua empresa crescer de maneira sustentável
  </Feature>
  <Feature small title='Administrativa' icon='briefcase'>
    Conheça suas fraquezas e transforme-as em pontos fortes para se destacar no mercado
  </Feature>
  <Feature small title='Consultoria Financeira' icon='funnel-dollar'>
    Organize sua finanças e coloque em prática melhores estratégias para crescer
  </Feature>
  {/* <Feature small title='Treinamentos' icon='users'>
    Ut nulla sit sit officia sit laboris id dolore sint tempor eu ut veniam.
  </Feature> */}
  </Section>
    <Section
      id='four'
      type='1'
      alt='special'
      header='Transforme a sua empresa'
      headerContent='Entre em contato e descubra o que mais a Proi Soluções pode fazer por você'
    >
      <ul className='action special'>
        <li>
          <Link to='/contato' className='button primary major'>
            Entre em contato
          </Link>
        </li>
      </ul>
    </Section>
  </>
);
