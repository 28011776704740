import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/img/logo.webp';

export default () => (
  <section id='banner'>
    <div className='inner'>
      <img
        src={logo}
        alt='Proi'
        style={{ width: '15vh', margin: 30, alignSelf: 'center' }}
      />
      <div className='content'>
        <h2>Proi</h2>
        <p>Soluções Empresariais</p>
      </div>
      <ul className='actions stacked'>
        <li>
          <Link to='/sobre-nos' className='button primary major'>
            Saiba mais
          </Link>
        </li>
        <li>
          <Link to='/contato' className='button major'>
            Entre em contato
          </Link>
        </li>
      </ul>
    </div>
  </section>
);
