import React from 'react';

import Section from '../components/Section';
import Parceiro from '../components/Parceiro';
import { Link } from 'react-router-dom';

import contabil from '../assets/img/proi-contabil.webp';
import clouds from '../assets/img/clouds-sistemas.webp';
import proenca from '../assets/img/proenca-proenca.webp';

export default () => (
  <>
  <Section
    id='main'
    type='2'
    header='Parceiros'
    headerContent='Amet et anim aute aliqua excepteur ut ex deserunt cupidatat.'
    content="features"
    outer
  >
  <Parceiro small title='Proença e Proença' icon='file-alt' image={proenca}>
    Cuide de todos os processos legais, ou receba assessoria para a tomada de decisões
    jurídicas
  </Parceiro>
  <Parceiro small title='Clouds Sistemas' icon='file-alt' image={clouds}>
    Cuide de todos os processos legais, ou receba assessoria para a tomada de decisões
    jurídicas
  </Parceiro>
  <Parceiro small title='Proi Contabil' icon='file-alt' image={contabil}>
    Cuide de todos os processos legais, ou receba assessoria para a tomada de decisões
    jurídicas
  </Parceiro>
  </Section>
    <Section
      id='four'
      type='1'
      alt='special'
      header='Transforme a sua empresa'
      headerContent='Entre em contato e descubra o que mais a Proi pode fazer por você'
    >
      <ul className='action special'>
        <li>
          <Link to='/contato' className='button primary major'>
            Entrar em contato
          </Link>
        </li>
      </ul>
    </Section>
  </>
);
