import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Menu from './Menu';
import logo from '../assets/img/logo.webp';

export default props => {
  const [menu, setMenu] = useState(false);

  const toggleMenu = () => {
    menu ? setMenu(false) : setMenu(true);
  };

  useEffect(() => {
    const panel = document.querySelector('#menu').classList;

    if (menu) {
      panel.add('visible');
    } else if (!menu && panel.contains('visible')) {
      panel.remove('visible');
    }
  }, [menu]);

  return (
    <>
      <header id='header' className={props.type}>
        <h1>
          <Link to='/'>
            <img src={logo} alt='Proi' style={{ height: '3vh', margin: 15 }} />
          </Link>
        </h1>
        <a href='#menu' onClick={toggleMenu}>
          Menu
        </a>
      </header>
      <Menu toggle={toggleMenu} />
    </>
  );
};
