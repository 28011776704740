import React from 'react';
import { render } from 'react-snapshot';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/sass/main.scss';

import App from './App';

render(
  <Router>
    <App />
  </Router>,
  document.querySelector('#root')
);
