import React from 'react';

import Section from '../components/Section';

export default () => (
  <Section
    id='main'
    header='Sobre nós'
    headerContent='Conheça um pouco mais sobre a Proi Soluções'
    outer
  >
    {/* <span className='image left'>
      <img src='images/pic08.jpg' alt='' />
    </span> */}
    <p className="align-justify">
      Vivemos em tempos de novas tecnologias, novos profissionais, novos problemas, e por
      conseguintes novas soluções. A revolução tecnológica, as constantes mudanças de
      sistemas e legislação, a velocidade das coisas e como acontecem, não permitem ao
      Empresário condições de atualização imediata, sendo necessário uma multidão de
      competências para a aplicação correta de conceitos e práticas empresariais no seu
      dia a dia.
    </p>
    <p className="align-justify">
      Temos em nosso DNA, e acreditamos na inovação, e na prática da
      multidisciplinariedade, produzindo soluções atualizadas e integradas entre as várias
      disciplinas para os problemas desta nova era de negócios Somos uma empresa de
      Consultoria, Diagnósticos, Assessoria e Treinamento Empresarial, com experiência no
      apoio Empresarial. Com a finalidade de oferecer, nas diversas áreas de atuação, o
      suporte necessário para uma atividade empresarial segura e eficaz, colocamos nossa
      experiência a disposição das Empresas Parceiras.
    </p>
    <p className="align-justify">
      Desde o início de nossas atividades, tivemos em nossa proposta de serviços, atingir
      os objetivos e as expectativas dos clientes, atendendo-os, sempre, de forma
      individualizada e segmentada, adaptando-se às exigências e peculiaridades de cada
      um, oferecendo soluções, de forma que a estrutura operacional e organizacional
      destes, efetivamente absorvam e assimilem corretamente as orientações advindas dos
      nossos profissionais. Temos profissionais altamente especializados nas áreas de
      necessidades de desenvolvimento empresarial, com suporte em Tecnologia da Informação
      através de Sistema Integrado de Gestão Empresarial (E.R.P), Direito, Segurança e
      Medicina no Trabalho, Administrativa, Financeira, Contábil, Treinamentos
      Empresariais e atividades diversas necessárias, posicionados de forma a eliminar
      riscos, abrir frentes de ataque e consolidar os conceitos legais a serem adotados
      por nossos clientes.
    </p>
    <p className="align-justify">
      A nossa atuação cognitiva, nesse sentido, tem por princípio básico analisar todos os
      problemas que ocorrem no cotidiano empresarial e em novos negócios que venham a ser
      desenvolvidos por nossos clientes, através de consultoria preventiva e corretiva e
      oferecendo soluções e profissionais especializados nas diversas áreas. O
      acompanhamento de todos esses trabalhos é realizado diariamente por toda a nossa
      equipe de profissionais e auxiliares e através de parcerias, fornecendo aos nossos
      clientes reduções de riscos em todos os procedimentos que se encontram sob os nossos
      cuidados, com atualizações periódicas. Nossa proposta de trabalho é de natureza
      técnica, administrativa e jurídica, voltado principalmente às áreas que envolvem as
      necessidades Empresariais, com a visão do trabalho preventivo e corretivo,
      fortalecendo sempre a característica da busca constante da qualidade dos serviços,
      através da informação e sua permanente atualização.
    </p>
    <hr />
    <h3>Nossa Missão</h3>
    <p className="align-justify">
      Promover através da inovação, e de uma visão cognitiva, o apoio necessário ao mundo
      Empresarial, fornecendo subsídios nas tomadas de decisões estratégicas, de forma que
      os resultados futuros das organizações, sejam otimizados e livres de riscos.
    </p>
    <hr />
    <h3>Nossa Visão</h3>
    <p className="align-justify">
      Ser uma Empresa que transforme a informação em resultados que agreguem valor aos
      nossos clientes
    </p>
    <hr />
    <h3>Nossos Valores</h3>
    <p className="align-justify">
      São pautados na ética, na moral e na certeza de que o Estado de Direito, é a
      segurança necessária para um futuro melhor.
    </p>
  </Section>
);
