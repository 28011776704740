import React from 'react';

export default props => {
  const videoUrl = `https://www.youtube.com/embed/${ props.video }`;
  const content = (
    <>
      <iframe width="100%" height="230"
        src={ videoUrl }
        frameBorder="0" allowFullScreen
        allow="accelerometer;clipboard-write;encrypted-media;gyroscope; picture-in-picture">
      </iframe>
      {props.small ? <h3>{props.title}</h3> : <p>{props.title}</p>}
      <div className="social">
          <span>Compartilhar Vídeo:</span>
          <a href={`whatsapp://send?text=${ props.title } ${ videoUrl }`} target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp-square"></i></a>
          <a href={`http://www.facebook.com/share.php?u=${ videoUrl }`} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square"></i></a>
          <a href={`https://twitter.com/intent/tweet?text=${props.title}&tw_p=tweetbutton&url=https://www.youtube.com/embed/${ videoUrl }`} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter-square"></i></a>
      </div>
    </>
  );
  return (
    <section>
      <div className='content'>{content}</div>
    </section>
  );
};