import React from 'react';

import Section from '../components/Section';
import Form from '../components/Form';

export default () => (
  <Section id='main' header='Contato' headerContent='Fale com a gente' outer>
    <Form />
  </Section>
);
