import React from 'react';

import solucoes from '../assets/img/proi-solucoes.webp';
import clouds from '../assets/img/clouds-sistemas.webp';
import proenca from '../assets/img/proenca-proenca.webp';
import contabil from '../assets/img/proi-contabil.webp';

export default () => (
  <div className='image-circles'>
    <div className='images'>
      <span className='image'>
        <a href='https://proisolucoes.com.br'>
          <img src={solucoes} alt='Demonstrativo' />
        </a>
      </span>
      <span className='image'>
        <a href='https://cloudssistemas.com.br'>
          <img src={clouds} alt='Demonstrativo' />
        </a>
      </span>
    </div>
    <div className='images'>
      <span className='image'>
        <a href='https://proencaeproenca.com.br'>
          <img src={proenca} alt='Demonstrativo' />
        </a>
      </span>
      <span className='image'>
        <a href='https://proencaeproenca.com.br'>
          <img src={contabil} alt='Demonstrativo' />
        </a>
      </span>
    </div>
  </div>
);
