import React from 'react';
import { Link } from 'react-router-dom';

import ImageCircles from './ImageCircles';

export default props => {
  const header = props.header && (
    <header className={props.headerClass}>
      <h2>{props.header}</h2>
      <p>{props.headerContent}</p>
    </header>
  );

  return (
    <section
      id={props.id}
      className={props.type ? `wrapper style${props.type} ${props.alt}` : `wrapper`}
    >
      {props.header && props.outer && header}
      <div className='inner'>
        {props.header && !props.outer && header}
        {props.content ? (
          <div className={`inner ${props.content}`}>{props.children}</div>
        ) : (
            props.children
          )}
        {props.imageCircles && <ImageCircles />}
        {props.link && (
          <footer className='major'>
            <ul className='action special'>
              <li>
                {props.linkExternal !== '1' ? (
                    <Link to={props.link} className='button major'>
                        {props.linkText}
                    </Link>
                ) : (
                    <a href={props.link} className='button major'>
                        {props.linkText}
                    </a>
                )}
              </li>
            </ul>
          </footer>
        )}
      </div>
    </section>
  );
};
