import React, { useState } from 'react';

export default () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [successAlert, setAlertSuccess] = useState( [false, ''] );
  const [errorAlert, setAlertError] = useState( [false, ''] );

  const _onSubmit = async e => {
    e.preventDefault();
    setAlertSuccess( [false, ''] )
    setAlertError( [false, ''] )
    
    var submitButton = document.getElementById('submitButton');
    submitButton.setAttribute("disabled", "disabled");

    try {
        const response = await fetch(`https://proisolucoes.com.br/mail/?name=${name}&email=${email}&msg=${msg}`)
        const json = await response.json();
        
        console.log( json )
        if ( json.status === 1 )
        {
            setAlertSuccess( [true, 'E-mail enviado com sucesso! Responderemos o mais breve possível.'] )
            _onReset();
        }
        else
            setAlertError( [true, json.msg] )
    } finally {
        submitButton.removeAttribute('disabled');
    }
  };

  const _onReset = () => {
    setName('');
    setEmail('');
    setMsg('');
  };

  return (
    <form action='#'>
        <div className="row">
            { successAlert[0] && <div className="col-12"><div className="form-alert success">{successAlert[1]}</div></div> }
            { errorAlert[0] && <div className="col-12"><div className="form-alert error">{errorAlert[1]}</div></div> }
        </div>
      <div className='row gtr-uniform'>
        <div className='col-6 col-12-xsmall'>
          <label htmlFor='name'>Nome</label>
          <input
            type='text'
            placeholder='Entre com seu nome'
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className='col-6 col-12-xsmall'>
          <label htmlFor='email'>E-mail</label>
          <input
            type='email'
            placeholder='Entre com o seu e-mail'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className='col-12'>
          <label htmlFor='message'>Mensagem</label>
          <textarea
            rows='6'
            placeholder='Entre a sua mensagem aqui'
            value={msg}
            onChange={e => setMsg(e.target.value)}
          />
        </div>
        <div className='col-12'>
          <ul className='actions'>
            <li>
              <input
                id="submitButton"
                type='submit'
                className='primary'
                value='Enviar'
                onClick={_onSubmit}
              />
            </li>
            <li>
              <input type='reset' value='Limpar' onClick={_onReset} />
            </li>
          </ul>
        </div>
      </div>
    </form>
  );
};
