import React from 'react';
import { Link } from 'react-router-dom';

import Section from '../components/Section';

export default () => (
  <Section id='main' header='Erro 404' outer>
    <h3 className="align-center">Esta página não existe</h3>
    <hr />
    <p className="align-center">
      Sentimos muito. A página que você está tentando acessar não existe ou não está disponível no momento.
    </p>
    <p className="align-center">
        <Link to='/contato' className='button primary major'>
            Entrar em contato
        </Link>
    </p>
  </Section>
);
