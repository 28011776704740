import React from 'react';

export default props => {
  const content = (
    <>
      {props.small ? <h3>{props.title}</h3> : <h2>{props.title}</h2>}
      <p>{props.children}</p>
    </>
  );
  return (
    <section class="parceiro">
      <span className='image'>
        <img src={props.image} alt='Spotlight' />
      </span>
      <div className='content'>{content}</div>
    </section>
  );
};
