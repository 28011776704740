import React from 'react';
import { Link } from 'react-router-dom';

export default props => {
  const links = [
    { path: '/', text: 'Home', id: 1 },
    { path: '/servicos', text: 'Serviços', id: 2 },
    // { path: '/videos', text: 'Vídeos', id: 3 },
    { path: '/sobre-nos', text: 'Sobre nós', id: 4 },
    { path: '/contato', text: 'Contato', id: 5 }
  ];

  return (
    <nav id='menu'>
      <ul className='links'>
        {links.map(link => (
          <li key={link.id}>
            <Link onClick={() => props.toggle()} to={link.path}>
              {link.text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
