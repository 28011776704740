import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Home from './pages/Home';
import Sobre from './pages/Sobre';
import Servicos from './pages/Servicos';
import Parceiros from './pages/Parceiros';
import Videos from './pages/Videos';
import Contato from './pages/Contato';
import Error404 from './pages/Error404';
import Wrapper from './components/Wrapper';
import ScrollToTop from './components/ScrollToTop';

export default withRouter(({ match }) => {
  const body = document.querySelector('body').classList;

  useEffect(() => {
    const timer = setTimeout(() => {
      body.remove('is-preload');
    }, 100);
    return () => clearTimeout(timer);
  }, [body]);

  return (
    <Wrapper header={match.isExact ? 'alt' : null}>
      <ScrollToTop />
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route path='/sobre-nos'>
          <Sobre />
        </Route>
        <Route path='/servicos'>
          <Servicos />
        </Route>
        <Route path='/parceiros'>
          <Parceiros />
        </Route>
        <Route path='/videos'>
          <Videos />
        </Route>
        <Route path='/contato'>
          <Contato />
        </Route>
        <Route>
          <Error404 />
        </Route>
      </Switch>
    </Wrapper>
  );
});
