import React from 'react';
import { Link } from 'react-router-dom';

import Banner from '../components/Banner';
import Section from '../components/Section';
import Feature from '../components/Feature';

import pic06 from '../assets/img/pic06.webp';
import pic07 from '../assets/img/dr-fernando-proenca.webp';

export default () => (
  <>
    <Banner />
    <Section id='one' type='1' alt='split' content='content' imageCircles>
      <h2>Tudo o que sua empresa precisa</h2>
      <p>
        A Proi Soluções tem como objetivo principal facilitar a vida de seus clientes. Em parceria com diversos colaboradores de diversas áreas, levamos as soluções necessárias para a sua empresa. Concentre-se na sua ideia e fique livre de tarefas administrativas que tomam seu tempo.
      </p>
      <ul className='actions'>
        <li>
          <Link to='/parceiros' className='button'>
            Conheça nossos parceiros
          </Link>
        </li>
      </ul>
    </Section>
    <Section
      id='two'
      type='2'
      content='features'
      header='Nossos serviços'
      headerClass='major'
      link='/servicos'
      linkText='Ver mais'
      headerContent='Seja a contabilidade ou o jurídico, nós temos o que a sua empresa precisa'
    >
      <Feature small title='Jurídico' icon='file-alt'>
        Cuide de todos os processos legais, ou receba assessoria para a tomada de decisões
        jurídicas
      </Feature>
      <Feature small title='Sistemas' icon='cloud'>
        Informatize a sua empresa com o nosso ERP proprietário ou consulte para a criação
        de soluções personalizadas
      </Feature>
      <Feature small title='Contábil' icon='calculator'>
        O controle financeiro e contábil na medida certa para a sua empresa crescer de maneira sustentável
      </Feature>
    </Section>
    <Section id='three' type='1' content='spotlights'>
      <Feature image={pic06} title='Profissionais de ponta'>
        Nós trabalhamos com uma equipe altamente especializada. Cada um de nossos
        colaboradores é referência em seu ramo de atuação. Assim, a Proi Soluções consegue oferecer
        a melhor qualidade para atender um grande número de serviços.
      </Feature>
      <Feature image={pic07} title='Experiência e tradição'>
        O idealizador da Proi Soluções, o Dr. Fernando Proença, atua no mercado empresarial há
        quase 30 anos. Sua experiência trabalhista e empreendedora contribui para liderar
        a nossa equipe para a excelência.
      </Feature>
    </Section>
    <Section
      id='four'
      type='2'
      alt='special'
      header='Transforme a sua empresa'
      headerContent='Entre em contato e descubra o que a Proi Soluções pode fazer por você'
    >
      <ul className='action special'>
        <li>
          <Link to='/contato' className='button primary major'>
            Entre em contato
          </Link>
        </li>
      </ul>
    </Section>
  </>
);
