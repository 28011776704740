import React, { useState } from 'react';

import Section from '../components/Section';
import Video from '../components/Video';

export default () => {
    const [videos, setVideos] = useState( [] );
    
    const _getVideos = async e => {
        if ( videos.length > 0 )
            return;

        try {
            const response = await fetch( 'https://proi.rafaelhs.com.br/_youtube/' );
            const json = await response.json();

            console.log( json );

            setVideos( json )
        } catch ( error ) {
            console.log( error );
        }
    }

    _getVideos();

    return (
        <Section
            id='main'
            type='2'
            header='Vídeos'
            headerContent='Seja a contabilidade ou o jurídico, nós temos o que a sua empresa precisa.'
            link='https://www.youtube.com/channel/UCOGIZpzABxyv-_Wo3vv-13w/videos'
            linkText='Ver mais Vídeos'
            linkExternal='1'
            content="videos"
            outer
        >
            {videos.map((video, index) => (
            <Video key={index} title={video.title} video={video.id}></Video>
            ))}
        </Section>
    );
};
